<template>
    <div>
        <!--
           Skiplinks link to landmarks in the website.
           Don't forget to add the IDs below to your custom elements.
           Keep in mind there can only be 1 <main> and 1 <footer> component in the context of the body
       -->
        <SkipLinks />

        <TheNavigation :apply-link />

        <div class="layout">
            <div class="layout__main">
                <slot />
            </div>
        </div>

        <TheFooter />

        <ClientOnly>
            <!-- fixme -->
            <TheCookiebar />
            <TheLoadingIndicator />
        </ClientOnly>
    </div>
</template>

<script setup>
const { isBglf } = useMultisite();
const applyLink = computed(() => {
    if (isBglf.value) {
        return '/aanvragen';
    }

    return '/aanvraag-doen';
});

const i18nHead = useLocaleHead({
    addDirAttribute: true,
    addSeoAttributes: true
});

const appConfig = useAppConfig();

useHead({
    htmlAttrs: {
        ...i18nHead.value.htmlAttrs,
    },
    bodyAttrs: {
        'data-theme': appConfig.theme
    }
});

useFavicon();
</script>
